import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Row, Table } from 'react-bootstrap';
import { AppPagination, Loading } from '../../components';
import { IProfileShort } from '../../core/models';
import { ProfilesService } from '../../services/Profiles';
import { useNavigate } from 'react-router-dom';
import { emptyProfileShort } from '../../core/constants';
import BranchModal from './UpdateBranchModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { UserService } from '../../services';
const AllProfilesPage = () => {
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState<IProfileShort[]>([]);
  const [modal, setModal] = useState({
    profile: emptyProfileShort,
    show: false,
  });
  const [loading, setLoading] = useState<Boolean>(false);
  const [pagination, setPagination] = useState({
    cur: 1,
    max: 1,
  });
  const [searchKey, setSearchKey] = useState('');

  const onOpenModal = (profile: IProfileShort) => {
    setModal({
      show: true,
      profile: profile,
    });
  };
  const onCloseModal = (e: boolean) => {
    setModal({
      show: false,
      profile: emptyProfileShort,
    });
    if (e) getProfiles(pagination.cur);
  };
  const getProfiles = async (page: number = 1) => {
    setLoading(true);
    try {
      const res = await ProfilesService.getAllProfiles(page);
      if (res.data?.length > 0) {
        setProfiles(res.data);
        setPagination({
          cur: res.pagination_data.page,
          max: res.pagination_data.pages,
        });
      } else {
        setProfiles([]);
        setPagination({
          cur: 1,
          max: 1,
        });
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };
  const openProfile = (id: Number) => {
    navigate(`/dashboard/profile/${id}`);
  };
  const disableProfile = async (id: number) => {
    try {
      const val = window.confirm('Do you want to disable profile ?');
      if (val) {
        const res = await ProfilesService.disableProfile(id);
        if (res.status === 200) {
          alert('Profile disabled successfully');
          getProfiles(pagination.cur);
        } else {
          alert('Error while enabling profile');
        }
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const searchProfile = async (page: number = 1) => {
    setLoading(true);
    try {
      const res = await ProfilesService.searchActiveProfiles(searchKey, page);
      if (res.data?.length > 0) {
        setProfiles(res.data);
        setPagination({
          cur: res.pagination_data.page,
          max: res.pagination_data.pages,
        });
      } else {
        setProfiles([]);
        setPagination({
          cur: 1,
          max: 1,
        });
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getProfiles();
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h2 className="page-title my-2">Profiles</h2>
          </Col>
        </Row>
        <Row className="my-3 justify-content-end">
          <Col md={4} className="text-end ">
            <InputGroup>
              <Form.Control
                placeholder="Search Name, SGS No, File No...  "
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
              />
              <Button variant="primary" onClick={() => searchProfile()}>
                Search
              </Button>
              {searchKey && (
                <Button
                  variant="secondary"
                  onClick={() => {
                    setSearchKey('');
                    getProfiles();
                  }}
                >
                  {' '}
                  <FontAwesomeIcon icon={faTimes} />{' '}
                </Button>
              )}
            </InputGroup>
          </Col>
          <Col md={2} className="text-end d-grid">
            <Button onClick={() => navigate('/dashboard/add-profile')}>Add Profile</Button>
          </Col>
        </Row>
        <>
          <Row>
            <Col>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Name</th>
                    <th>File No.</th>
                    <th>SGS No.</th>
                    <th>Phone</th>
                    <th>Update Branch</th>
                    {UserService.isAdmin() && <th>Disable</th>}
                  </tr>
                </thead>
                <tbody>
                  {!loading &&
                    profiles.length > 0 &&
                    profiles.map((i, index) => (
                      <tr key={i.id} onClick={() => openProfile(i.id)}>
                        <td>{index + 1}</td>
                        <td>{i.name}</td>
                        <td>{i.fileNo}</td>
                        <td>{i.sgsNo}</td>
                        <td>{i.contactDetails.mobileNo1}</td>
                        <td>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              onOpenModal(i);
                            }}
                          >
                            Update Branch
                          </Button>
                        </td>
                        {UserService.isAdmin() && (
                          <td>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                disableProfile(i.id);
                              }}
                              variant="outline-danger"
                            >
                              Disable
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Col>
            {loading && <Loading />}
            {!loading && profiles.length === 0 && (
              <h4 className="text-secondary text-center my-5 py-5">No Records Found</h4>
            )}
            <div className="d-flex justify-content-center my-3">
              <AppPagination
                curPage={pagination.cur}
                maxPage={pagination.max}
                changePage={(n) => (searchKey ? searchProfile(n) : getProfiles(n))}
              />
            </div>
          </Row>
        </>
      </Container>
      <BranchModal show={modal.show} profile={modal.profile} onClose={onCloseModal} />
    </>
  );
};
export default AllProfilesPage;
