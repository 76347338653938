import axios, { AxiosRequestConfig } from 'axios';
import { UserService } from '../services';

const DEFAULT_TIMEOUT = 60 * 1000 * 3;

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: DEFAULT_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use((config) => {
  const token = JSON.parse(localStorage.getItem('user'))?.accessToken || false;
  if (token) {
    config.headers.Authorization = token;
    return config;
  } else {
    return config;
  }
});

apiClient.interceptors.response.use(
  (response) => {
    return { ...response.data, status: response.status };
  },
  (error) => {
    if (error.response.data.error.code === 403) {
      UserService.logout();
      window.location.href = window.location.origin + '/auth/login?session=true';
      return { ...error.response };
    } else {
      Promise.reject(error);
    }
  }
);

export class NetworkManager {
  static MyInstance: NetworkManager;
  static getInstance(): NetworkManager {
    if (!NetworkManager.MyInstance) {
      NetworkManager.MyInstance = new NetworkManager();
    }
    return NetworkManager.MyInstance;
  }
  apiClient = apiClient;
  appRequest = async (options: AxiosRequestConfig) => {
    return apiClient(options) as Promise<any>;
  };
}
