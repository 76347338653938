import React, { useEffect, useState } from 'react';
import { BranchDetails, IAddUser, IUser, RoleDetails } from '../../core/models';
import { ModalModes } from '../../core/enums';
import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { MasterService, UserService } from '../../services';
interface UserModalProps {
  show: boolean;
  user?: IUser;
  mode: ModalModes;
  onClose: (e?: boolean) => void;
}
const UserModal = (props: UserModalProps) => {
  const [userForm, setUserForm] = useState<IAddUser>({
    name: '',
    email: '',
    password: '',
    roleId: null,
    branchId: null,
  });
  const [error, setError] = useState({
    name: '',
    email: '',
    password: '',
    roleId: '',
    branchId: '',
  });
  const [mode, setMode] = useState<ModalModes>(ModalModes.VIEW);
  const [branch, setBranch] = useState<BranchDetails[]>([]);
  const [roles, setRoles] = useState<RoleDetails[]>([]);
  const getBranches = async () => {
    try {
      const res = await MasterService.getBranches(1, 100);
      if (res.status === 200) {
        if (res.data.records.length > 0) {
          setBranch(res.data.records);
        }
      }
    } catch (error) {
      alert('Error while fetching branches');
    }
  };
  const getRoles = async () => {
    try {
      const res = await MasterService.getRoles(1, 100);
      if (res.status === 200) {
        if (res.data.records.length > 0) {
          setRoles(res.data.records);
        }
      }
    } catch (error) {
      alert('Error while fetching roles');
    }
  };
  const isValid = () => {
    let temp = { name: '', email: '', password: '', roleId: '', branchId: '' };
    let valid = true;
    if (!userForm.name) {
      temp.name = 'Required';
      valid = false;
    }
    if (!userForm.email) {
      temp.email = 'Required';
      valid = false;
    }
    if (!userForm.password && mode === ModalModes.ADD) {
      temp.password = 'Required';
      valid = false;
    }
    if (!userForm.branchId && userForm.roleId === 2) {
      temp.branchId = 'Required';
      valid = false;
    }
    if (!userForm.roleId) {
      temp.roleId = 'Required';
      valid = false;
    }
    setError(temp);
    return valid;
  };
  const addUser = async () => {
    try {
      if (isValid()) {
        const res = await UserService.addUser(userForm);
        if (res.status === 200) {
          alert('User Created Successfully');
          props.onClose(true);
        } else {
          alert(res.data.error.message);
        }
      }
    } catch (error) {
      alert('Error while adding user');
    }
  };
  const updateUser = async () => {
    try {
      if (isValid()) {
        const res = await UserService.updateUser({ ...userForm, id: props.user.id });
        if (res.status === 200) {
          alert('User Updated Successfully');
          props.onClose(true);
        } else {
          alert(res.data.error.message);
        }
      }
    } catch (error) {
      alert('Error while adding user');
    }
  };
  const deleteUser = async () => {
    try {
      const val = window.confirm(`Do you really want to delete user ${userForm.name}?`);
      if (val) {
        const res = await UserService.deleteUser(props.user.id);
        if (res.status === 200) {
          alert('User Deleted Successfully');
          props.onClose(true);
        } else {
          alert(res.data.error.message);
        }
      }
    } catch (error) {
      alert('Error while deleting user');
    }
  };
  useEffect(() => {
    getBranches();
    getRoles();
  }, []);
  useEffect(() => {
    setUserForm({
      name: props.user.name,
      email: props.user.email,
      password: '',
      roleId: props.user.roleDetails.id,
      branchId: props.user.branchesDetails?.id || null,
    });
    setMode(props.mode);
  }, [props]);
  return (
    <Modal show={props.show} onHide={() => props.onClose()} size="lg">
      <Modal.Header className="bg-primary text-light" closeButton>
        <Modal.Title>{mode} User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12}>
              <form>
                <FloatingLabel label="Name" className="mb-3">
                  <Form.Control
                    name="name"
                    placeholder="Name"
                    value={userForm.name}
                    onChange={(e) => setUserForm({ ...userForm, name: e.target.value })}
                    isInvalid={!!error.name}
                    readOnly={mode === ModalModes.VIEW}
                  />
                  {error.name && <p className="error">{error.name}</p>}
                </FloatingLabel>
                <FloatingLabel label="Email" className="mb-3">
                  <Form.Control
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={userForm.email}
                    onChange={(e) => setUserForm({ ...userForm, email: e.target.value })}
                    isInvalid={!!error.email}
                    readOnly={mode === ModalModes.VIEW}
                  />
                  {error.email && <p className="error">{error.email}</p>}
                </FloatingLabel>
                {mode === ModalModes.ADD && (
                  <FloatingLabel label="Password" className="mb-3">
                    <Form.Control
                      name="password"
                      placeholder="Password"
                      value={userForm.password}
                      type="password"
                      onChange={(e) => setUserForm({ ...userForm, password: e.target.value })}
                      isInvalid={!!error.password}
                    />
                    {error.password && <p className="error">{error.password}</p>}
                  </FloatingLabel>
                )}
                <FloatingLabel label="Role" className="mb-3">
                  <Form.Select
                    name="role"
                    value={userForm.roleId}
                    onChange={(e) => setUserForm({ ...userForm, roleId: Number(e.target.value) })}
                    isInvalid={!!error.roleId}
                    disabled={mode === ModalModes.VIEW}
                  >
                    <option value="">Select Role</option>
                    {roles.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.role}
                      </option>
                    ))}
                  </Form.Select>
                  {error.roleId && <p className="error">{error.roleId}</p>}
                </FloatingLabel>
                {userForm.roleId === 2 && (
                  <FloatingLabel label="Branch" className="mb-3">
                    <Form.Select
                      name="branch"
                      value={userForm.branchId}
                      onChange={(e) =>
                        setUserForm({ ...userForm, branchId: Number(e.target.value) })
                      }
                      isInvalid={!!error.branchId}
                      disabled={mode === ModalModes.VIEW}
                    >
                      <option value="">Select Branch</option>
                      {branch.map((i) => (
                        <option value={i.id} key={i.id}>
                          {i.branch_name}
                        </option>
                      ))}
                    </Form.Select>
                    {error.branchId && <p className="error">{error.branchId}</p>}
                  </FloatingLabel>
                )}
                <div className="d-grid my-3">
                  {mode === ModalModes.ADD && <Button onClick={addUser}>Create User</Button>}
                  {mode === ModalModes.EDIT && <Button onClick={updateUser}>Update User</Button>}
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      {mode === ModalModes.VIEW && (
        <Modal.Footer>
          <Button type="button" onClick={() => setMode(ModalModes.EDIT)} disabled>
            Edit
          </Button>
          <Button variant="outline-danger" type="button" onClick={deleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};
export default UserModal;
