import React, { useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserService } from '../../services';

const LoginPage = () => {
  const navigate = useNavigate();
  const [session, setSession] = useSearchParams();

  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState({
    email: '',
    password: '',
  });
  const isValid = () => {
    let temp = { email: '', password: '' };
    let flag = true;
    if (!loginForm.email) {
      temp.email = 'Required';
      flag = false;
    }
    if (!loginForm.password) {
      temp.password = 'Required';
      flag = false;
    }
    setError(temp);
    return flag;
  };
  const onLogin = async () => {
    if (isValid()) {
      try {
        const res = await UserService.login(loginForm.email, loginForm.password);
        if (res.status === 200) {
          UserService.setUser(res.data);
          navigate('/dashboard');
        } else {
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };
  return (
    <div className="full-page d-flex align-items-center justify-content-center bg-light">
      <div className="login box p-3">
        <h2 className="script border-bottom text-center mb-4 pb-2">Lingayath Weds</h2>
        <p className="text-center bold fs-5">Admin Page login</p>
        <FloatingLabel label="Email" className="my-3">
          <Form.Control
            name="email"
            value={loginForm.email}
            onChange={(e) => setLoginForm({ ...loginForm, email: e.target.value })}
            type="email"
            isInvalid={!!error.email}
            placeholder="Email"
          />
          {error.email && <p className="error">{error.email}</p>}
        </FloatingLabel>
        <FloatingLabel label="Password" className="my-3">
          <Form.Control
            name="password"
            placeholder="Password"
            value={loginForm.password}
            onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
            onKeyDown={(e) => {
              if (e.code === 'Enter') onLogin();
            }}
            type="password"
            isInvalid={!!error.password}
          />
          {error.password && <p className="error">{error.password}</p>}
        </FloatingLabel>
        <div className="d-grid my-3">
          <Button type="submit" onClick={onLogin}>
            Login
          </Button>
        </div>
        <div className="text-center my-3">
          <p className="link" onClick={() => navigate('/auth/forgot-password')}>
            Forgot Password?
          </p>
        </div>
        {session.get('session') && (
          <p className="text-danger text-center">
            Your session has expired. Please log in again to continue.
          </p>
        )}
      </div>
    </div>
  );
};

export default LoginPage;
