import React, { useEffect, useState } from 'react';
import { BranchDetails, IProfileShort } from '../../core/models';
import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from 'react-bootstrap';
import { MasterService, ProfilesService } from '../../services';
interface BranchModalProps {
  show: boolean;
  profile: IProfileShort;
  onClose: (e?: boolean) => void;
}

const BranchModal = (props: BranchModalProps) => {
  const [userForm, setUserForm] = useState({
    branchId: null,
  });
  const [error, setError] = useState({
    branchId: '',
  });
  const [branch, setBranch] = useState<BranchDetails[]>([]);
  const getBranches = async () => {
    try {
      const res = await MasterService.getBranches(1, 100);
      if (res.status === 200) {
        if (res.data.records.length > 0) {
          setBranch(res.data.records);
        }
      }
    } catch (error) {
      alert('Error while fetching branches');
    }
  };

  const isValid = () => {
    let temp = { branchId: '' };
    let valid = true;
    if (!userForm.branchId) {
      temp.branchId = 'Required';
      valid = false;
    }
    setError(temp);
    return valid;
  };
  const updateBranch = async () => {
    try {
      if (isValid()) {
        const res = await ProfilesService.updateProfileBranch(props.profile.id, userForm.branchId);
        if (res.status === 200) {
          alert('Branch Updated Successfully');
          props.onClose(true);
        } else {
          alert(res.data.error.message);
        }
      }
    } catch (error) {
      alert('Error while updatign branch');
    }
  };

  useEffect(() => {
    getBranches();
  }, []);

  return (
    <Modal show={props.show} onHide={() => props.onClose()} size="lg">
      <Modal.Header className="bg-primary text-light" closeButton>
        <Modal.Title>Update Branch</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col md={12}>
              <p>
                Update branch for profile - {props.profile.name}({props.profile.fileNo})
              </p>
            </Col>
            <Col md={12}>
              <form>
                <FloatingLabel label="Branch" className="mb-3">
                  <Form.Select
                    name="branch"
                    value={userForm.branchId}
                    onChange={(e) => setUserForm({ ...userForm, branchId: Number(e.target.value) })}
                    isInvalid={!!error.branchId}
                  >
                    <option value="">Select Branch</option>
                    {branch.map((i) => (
                      <option value={i.id} key={i.id}>
                        {i.branch_name}
                      </option>
                    ))}
                  </Form.Select>
                  {error.branchId && <p className="error">{error.branchId}</p>}
                </FloatingLabel>
                <div className="d-grid my-3">
                  <Button onClick={updateBranch}>Update</Button>
                </div>
              </form>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
export default BranchModal;
