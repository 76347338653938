import { HTTP } from '../core/enums';
import { ApiEndPoints, NetworkManager } from '../network';

export class TestimonialService {
  static getTestimonials = (page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.testimonials + '/getActiveTestimonials',
      params: { page, limit },
    });
  };
  static addTestimonial = (data: any) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.testimonials + '/add',
      data: { ...data },
    });
  };
  static updateTestimonial = (data: any) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.PUT,
      url: ApiEndPoints.testimonials + '/update',
      data: { ...data },
    });
  };
  static updateTestimonialImage = (data: any) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.testimonials + '/updateImage',
      data: { ...data },
    });
  };
  static delete = (data: any) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.DELETE,
      url: ApiEndPoints.testimonials + '/delete/' + data,
    });
  };
}
