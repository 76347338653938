import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from 'react-bootstrap';
import { AppPagination, Loading } from '../../components';
import { IProfileShort, ITestimony } from '../../core/models';
import { ProfilesService } from '../../services/Profiles';
import { useNavigate } from 'react-router-dom';
import { emptyProfileShort } from '../../core/constants';
import BranchModal from '../AllProfiles/UpdateBranchModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { TestimonialService, UserService } from '../../services';
import {
  faCamera,
  faEdit,
  faImagePortrait,
  faPlay,
  faPlayCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
const TestimonialsPage = () => {
  const navigate = useNavigate();
  const [testimonials, setTestimonials] = useState<ITestimony[]>([]);
  const [modal, setModal] = useState({
    show: false,
    mode: '',
  });
  const resetTest = {
    id: null,
    bride: '',
    groom: '',
    yt: '',
    desc: '',
    image: '',
    eimage: '',
    error: {
      bride: '',
      groom: '',
      image: '',
      desc: '',
    },
  };
  const [newtest, setTest] = useState(resetTest);
  const [newImageModal, setNewImage] = useState({
    show: false,
    id: 0,
  });
  const [loading, setLoading] = useState<Boolean>(false);
  const [pagination, setPagination] = useState({
    cur: 1,
    max: 1,
  });
  const getTestimonials = async (page: number = 1) => {
    setLoading(true);
    try {
      const res = await TestimonialService.getTestimonials(page);
      if (res.data?.length > 0) {
        setTestimonials(res.data);
        setPagination({
          cur: res.pagination_data.page,
          max: res.pagination_data.pages,
        });
      } else {
        setTestimonials([]);
        setPagination({
          cur: 1,
          max: 1,
        });
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };
  const validate = () => {
    let flag = true;
    let temp = {
      bride: '',
      groom: '',
      image: '',
      desc: '',
    };
    if (!newtest.groom) {
      temp.groom = 'Mandatory field';
      flag = false;
    }
    if (!newtest.bride) {
      temp.groom = 'Mandatory field';
      flag = false;
    }
    if (!newtest.desc) {
      temp.groom = 'Mandatory field';
      flag = false;
    }
    if (!newtest.image && modal.mode === 'Create') {
      temp.groom = 'Mandatory field';
      flag = false;
    }
    return flag;
  };
  const addTestimonial = async () => {
    if (validate()) {
      try {
        const res = await TestimonialService.addTestimonial({
          brideName: newtest.bride,
          groomName: newtest.groom,
          imageUrl: newtest.image,
          youtubeLink: newtest.yt,
          testimony: newtest.desc,
        });
        if (res.status === 200) {
          alert('Testimonial added successfully');
          getTestimonials();
          setTest(resetTest);
          setModal({ show: false, mode: '' });
        } else {
          alert('Error while adding testimonial');
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };
  const updateTestimonial = async () => {
    if (validate()) {
      try {
        const res = await TestimonialService.updateTestimonial({
          brideName: newtest.bride,
          groomName: newtest.groom,
          youtubeLink: newtest.yt,
          testimony: newtest.desc,
          imageUrl: newtest.eimage,
          id: newtest.id,
        });
        if (res.status === 200) {
          alert('Testimonial updated successfully');
          getTestimonials();
          setTest(resetTest);
          setModal({ show: false, mode: '' });
        } else {
          alert('Error while updating testimonial');
        }
      } catch (error) {
        alert(error.message);
      }
    }
  };
  const updateImage = async () => {
    if (newtest.image) {
      try {
        const res = await TestimonialService.updateTestimonialImage({
          imageUrl: newtest.image,
          id: newImageModal.id,
        });
        if (res.status === 200) {
          alert('Image updated successfully');
          getTestimonials();
          setTest(resetTest);
          setNewImage({ show: false, id: 0 });
        } else {
          alert('Error while updating image');
        }
      } catch (error) {
        alert(error.message);
      }
    } else {
      setTest({ ...newtest, error: { ...newtest.error, image: 'Mandatory Field' } });
    }
  };
  const deleteTestimony = async (id: number) => {
    try {
      const val = window.confirm('Do you want to delete testimony ?');
      if (val) {
        const res = await TestimonialService.delete(id);
        if (res.status === 200) {
          alert('Testimony deleted successfully');
          getTestimonials();
        } else {
          alert('Error while deleting Testimony');
        }
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const handleFileInputChange = (event: any) => {
    const allFiles = Array.from(event.target.files as FileList);
    const selectedFiles = allFiles.filter((file) => file.size < 300 * 1024); // Filter files less than 300 KB
    if (selectedFiles.length !== allFiles.length) {
      alert('Some files are larger than 300 KB and were not selected.');
      event.target.value = null;
      event.target.files = null;
      setTest({
        ...newtest,
        error: { ...newtest.error, image: 'Image cannot be more than 300KB' },
      });
      return;
    }
    if (selectedFiles.length > 1) {
      alert('You can only select 1 file');
      event.target.value = null;
      event.target.files = null;
      setTest({ ...newtest, error: { ...newtest.error, image: 'Maximum 1 image is allowed' } });
      return;
    }
    const promises = selectedFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({ name: file.name, base64: e.target.result });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });
    Promise.all(promises)
      .then((base64Files: any) => {
        setTest({
          ...newtest,
          image: base64Files.map((x: { base64: string }) => x.base64.split('base64,')[1])[0],
        });
      })
      .catch((error) => {
        console.error('Error reading files:', error);
      });
  };
  useEffect(() => {
    getTestimonials();
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h2 className="page-title my-2">Testimonials</h2>
            <div className="text-end my-2">
              <Button onClick={() => setModal({ show: true, mode: 'Create' })}>
                Add Testimonial
              </Button>
            </div>
          </Col>
        </Row>
        <>
          <Row>
            {!loading &&
              testimonials.map((i) => (
                <Col md={4} key={i.id}>
                  <div className="testimony">
                    <div className="image">
                      <img src={i.imageUrl} alt="" />
                      <Button
                        variant="outline-primary"
                        className="border-0 btn-img-update"
                        onClick={() => {
                          setNewImage({ show: true, id: i.id });
                        }}
                      >
                        <FontAwesomeIcon icon={faCamera} />
                      </Button>
                    </div>
                    <div className="body">
                      <h4>
                        {i.brideName} and {i.groomName}
                      </h4>
                      <p>{i.testimony}</p>

                      <div className="d-flex justify-content-between mt-2">
                        <Button variant="danger">
                          Youtube <FontAwesomeIcon icon={faPlayCircle} />
                        </Button>
                        <div>
                          <Button
                            variant="outline-primary"
                            className="border-0"
                            onClick={() => {
                              setModal({ show: true, mode: 'Update' });
                              setTest({
                                ...newtest,
                                id: i.id,
                                bride: i.brideName,
                                groom: i.groomName,
                                yt: i.youtubeLink,
                                desc: i.testimony,
                                eimage: i.imageUrl,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          {UserService.isAdmin() && (
                            <Button
                              variant="outline-danger"
                              className="border-0"
                              onClick={() => deleteTestimony(i.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            {loading && <Loading />}
            {!loading && testimonials.length === 0 && (
              <h4 className="text-secondary text-center my-5 py-5">No Records Found</h4>
            )}
            <div className="d-flex justify-content-center my-3">
              <AppPagination
                curPage={pagination.cur}
                maxPage={pagination.max}
                changePage={(n) => getTestimonials(n)}
              />
            </div>
          </Row>
        </>
      </Container>
      <Modal
        show={modal.show}
        onHide={() => {
          setModal({ show: false, mode: '' });
          setTest(resetTest);
        }}
      >
        <Modal.Header className="bg-primary text-light" closeButton>
          <Modal.Title>{modal.mode} Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FloatingLabel label="Bride" className="mb-3">
            <Form.Control
              name="bride"
              placeholder="Bride"
              value={newtest.bride}
              onChange={(e) => setTest({ ...newtest, bride: e.target.value })}
              isInvalid={!!newtest.error.bride}
            />

            {newtest.error.bride && <p className="error">{newtest.error.bride}</p>}
          </FloatingLabel>
          <FloatingLabel label="Groom" className="mb-3">
            <Form.Control
              name="groom"
              placeholder="Groom"
              value={newtest.groom}
              onChange={(e) => setTest({ ...newtest, groom: e.target.value })}
              isInvalid={!!newtest.error.groom}
            />

            {newtest.error.groom && <p className="error">{newtest.error.groom}</p>}
          </FloatingLabel>
          <FloatingLabel label="Description" className="mb-3">
            <Form.Control
              as="textarea"
              rows={3}
              name="desc"
              placeholder="Description"
              value={newtest.desc}
              onChange={(e) => setTest({ ...newtest, desc: e.target.value })}
              isInvalid={!!newtest.error.desc}
            />

            {newtest.error.desc && <p className="error">{newtest.error.desc}</p>}
          </FloatingLabel>
          <FloatingLabel label="Youtube Video Link" className="mb-3">
            <Form.Control
              name="yt"
              placeholder="Youtube Video Link"
              value={newtest.yt}
              onChange={(e) => setTest({ ...newtest, yt: e.target.value })}
            />
          </FloatingLabel>
          {modal.mode === 'Create' && (
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Label>Upload Your Image*</Form.Label>
              <Form.Control
                type="file"
                accept=".jpg,.jpeg,.png"
                multiple
                onChange={handleFileInputChange}
                isInvalid={!!newtest.error.image}
              />
              <p className="text-sm">1 image | Max Size 300kb | Dimension 300*400</p>
              {newtest.error.image && <p className="error">{newtest.error.image}</p>}
            </Form.Group>
          )}
          <div className="d-grid my-3">
            <Button
              onClick={() => {
                modal.mode === 'Create' ? addTestimonial() : updateTestimonial();
              }}
            >
              {modal.mode}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={newImageModal.show}
        onHide={() => {
          setNewImage({ show: false, id: 0 });
          setTest(resetTest);
        }}
      >
        <Modal.Header className="bg-primary text-light" closeButton>
          <Modal.Title>Update Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Upload Your Image*</Form.Label>
            <Form.Control
              type="file"
              accept=".jpg,.jpeg,.png"
              multiple
              onChange={handleFileInputChange}
              isInvalid={!!newtest.error.image}
            />
            <p className="text-sm">1 image | Max Size 300kb | Dimension 300*400</p>
            {newtest.error.image && <p className="error">{newtest.error.image}</p>}
          </Form.Group>
          <div className="d-grid my-3">
            <Button
              onClick={() => {
                updateImage();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TestimonialsPage;
