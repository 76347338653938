import { HTTP } from '../core/enums';
import { ApiEndPoints, NetworkManager } from '../network';
import { IAddProfile, IEditProfile } from '../pages/AddProfile/types';

export class ProfilesService {
  static getAllProfiles = (page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.profiles + '/admin/getActiveProfiles',
      params: { page, limit },
    });
  };
  static getNewProfiles = (page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.profiles + '/admin/getNewProfiles',
      params: { page, limit },
    });
  };
  static getRejectedProfiles = (page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.profiles + '/admin/getRejectedProfiles',
      params: { page, limit },
    });
  };
  static searchActiveProfiles = (query: string, page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.profiles + '/admin/searchActiveProfiles',
      params: { query, page, limit },
    });
  };
  static searchDisabledProfiles = (query: string, page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.profiles + '/admin/searchDisabledProfiles',
      params: { query, page, limit },
    });
  };
  static searchNewProfiles = (query: string, page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.profiles + '/admin/searchNewProfiles',
      params: { query, page, limit },
    });
  };
  static searchRejectedProfiles = (query: string, page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.profiles + '/admin/searchRejectedProfiles',
      params: { query, page, limit },
    });
  };
  // static getNewProfiles = (page: number = 1, limit: number = 10) => {
  //   return NetworkManager.getInstance().appRequest({
  //     method: HTTP.GET,
  //     url: ApiEndPoints.profiles + '/filteredProfiles',
  //     params: { page, limit },
  //   });
  // };
  static getDisabledProfiles = (page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.profiles + '/admin/getDisabledProfiles',
      params: { page, limit },
    });
  };
  static getProfileById = (id: number) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.profiles + '/findByProfileId/' + id,
    });
  };
  static addProfile = (profile: IAddProfile) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.profiles + '/register',
      data: { ...profile },
    });
  };
  static updateProfile = (profile: IEditProfile) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.PUT,
      url: ApiEndPoints.profiles + '/admin/update',
      data: { ...profile },
    });
  };
  static enableProfile = (id: number) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.profiles + '/admin/enable/' + id,
    });
  };
  static disableProfile = (id: number) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.DELETE,
      url: ApiEndPoints.profiles + '/admin/disable/' + id,
    });
  };
  static updateProfileBranch = (id: number, branch: number) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.profiles + '/admin/updateBranch/' + id + '/' + branch,
    });
  };

  static addNewImage = (profileId: number, image: string) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.profiles + '/admin/addNewProfileImage',
      data: { profileId, image },
    });
  };
  static deleteImage = (id: number) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.profiles + '/admin/deleteProfileImage/' + id,
    });
  };
  static updateProfileStatus = (profileId: number, profileStatusId: number, fileNo: string) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.PUT,
      url: ApiEndPoints.approve,
      data: { profileId, profileStatusId, fileNo },
    });
  };
}
