import { HTTP } from '../core/enums';
import { IAddUser, ILogin } from '../core/models';
import { ApiEndPoints, NetworkManager } from '../network';

export class UserService {
  static isLoggedin = () => {
    const user: ILogin = JSON.parse(localStorage.getItem('user') || 'false');
    return user.accessToken ? true : false;
  };
  static login = (email: string, password: string) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.login,
      data: { email, password },
    });
  };
  static setUser = (user: ILogin) => {
    localStorage.setItem('user', JSON.stringify(user));
  };
  static logout = () => {
    localStorage.clear();
  };
  static getUsers = (page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.users,
      params: { page, limit },
    });
  };
  static addUser = (user: IAddUser) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.users,
      data: { ...user },
    });
  };
  static updateUser = (user: IAddUser) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.users,
      data: { ...user },
    });
  };
  static deleteUser = (id: number) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.DELETE,
      url: ApiEndPoints.users + '/' + id,
    });
  };
  static isAdmin = () => {
    return JSON.parse(localStorage.getItem('user'))?.roleDetails.id === 1;
  };
}
