export enum HTTP {
  POST = 'post',
  GET = 'get',
  DELETE = 'delete',
  PUT = 'put',
}
export enum ModalModes {
  ADD = 'Add',
  EDIT = 'Edit',
  VIEW = 'View',
}
