import React from 'react';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { UserService } from '../services';

const Header = () => {
  const navigate = useNavigate();
  const onLogout = () => {
    UserService.logout();
    navigate('/auth/login');
  };
  return (
    <Navbar expand="lg" className="bg-light box py-md-3 py-1 px-lg-5 px-3">
      <Navbar.Brand>
        <Link className="logo" to={'/'}>
          Lingayath Weds
        </Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto">
          <Nav.Link onClick={() => navigate('/dashboard')}>Dashboard</Nav.Link>
          <NavDropdown title="Profile" id="basic-nav-dropdown">
            <NavDropdown.Item onClick={() => navigate('/dashboard/all-profiles')}>
              All Profiles
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate('/dashboard/new-profiles')}>
              New Profiles
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate('/dashboard/rejected-profiles')}>
              Rejected Profiles
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => navigate('/dashboard/disabled-profiles')}>
              Disabled
            </NavDropdown.Item>
          </NavDropdown>

          {UserService.isAdmin() && (
            <Nav.Link onClick={() => navigate('/dashboard/users')}>Users</Nav.Link>
          )}
          <Nav.Link onClick={onLogout} className="text-danger">
            Logout
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
export default Header;
