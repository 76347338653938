import React, { useEffect, useRef, useState } from 'react';
import { IEditProfile } from '../AddProfile/types';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { ProfilesService } from '../../services';
import { IProfile, ProfileImage } from '../../core/models';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingModal } from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const UpdateProfilePhotoPage = () => {
  const imageInput = useRef<HTMLInputElement>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [profileImages, setProfileImages] = useState<ProfileImage[]>([]);
  const [loading, setLoading] = useState(false);
  const getProfileDetails = async () => {
    setLoading(true);
    try {
      const res = await ProfilesService.getProfileById(Number(id));
      if (res.status === 200) {
        const d: IProfile = res.data;
        setProfileImages(d.profileImages);
      } else {
        alert('Error while fetching user details');
      }
      setLoading(false);
    } catch (error) {
      alert('Error while fetching user details');
      setLoading(false);
    }
  };

  const addImage = (e: any) => {
    const selectedFile = e.target.files[0];
    let base64Data: string = '';
    if (!selectedFile) {
      return;
    }
    if (selectedFile.size > 300 * 1024) {
      // Convert KB to bytes
      alert('File size exceeds 300KB limit. Please select a smaller file.');
      e.target.files = null;
      return;
    }
    const reader = new FileReader();
    reader.onload = async (e) => {
      base64Data = e.target.result.toString().split(',')[1];
      setLoading(true);
      try {
        const res = await ProfilesService.addNewImage(Number(id), base64Data);
        if (res.status === 200) {
          alert('Image Added Successfully');
          getProfileDetails();
        } else {
          alert('Error while adding image');
        }
        setLoading(false);
      } catch (error) {
        alert('Error while adding image');
        setLoading(false);
      }
    };
    reader.readAsDataURL(selectedFile);
  };
  const deleteImage = async (e: number) => {
    const con = window.confirm('Do you really want to delete this image?');
    if (con) {
      setLoading(true);
      try {
        const res = await ProfilesService.deleteImage(e);
        if (res.status === 200) {
          alert('Image Deleted');
          getProfileDetails();
        } else {
          alert('Error while deleteing image');
        }
        setLoading(false);
      } catch (error) {
        alert('Error while deleteing image');
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  return (
    <>
      <h1 className="heading bg-light mb-0 text-center py-4">Update Profile Photo</h1>
      <Container>
        <Row className="my-4">
          <Col>
            <div className="box p-3 my-3">
              <input
                type="file"
                accept=".jpg,.jpeg,.png"
                hidden
                onChange={addImage}
                ref={imageInput}
              />
              <Row className="mt-2 align-items-center">
                {profileImages.map((pi, index) => (
                  <Col lg={4} className="text-center" key={index}>
                    <div className="add-profile-image">
                      <img src={pi.imageUrl} alt={pi.id.toString()} />
                      {profileImages.length > 1 && (
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="delete-image-btn"
                          onClick={() => deleteImage(pi.id)}
                        />
                      )}
                    </div>
                  </Col>
                ))}
                {profileImages.length < 2 && (
                  <Col lg={4} className="text-center">
                    <Button
                      variant="secondary"
                      className="add-image-btn"
                      onClick={() => imageInput.current.click()}
                    >
                      Add Image
                    </Button>
                  </Col>
                )}
              </Row>
              <p className="text-secondary text-end">*Select files less than 300KB</p>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={3} className="d-grid">
            <Button onClick={() => navigate('/dashboard/profile/' + id)}>Back</Button>
          </Col>
        </Row>
        <div className="my-5"></div>
      </Container>
      <LoadingModal show={loading} />
    </>
  );
};
export default UpdateProfilePhotoPage;
