import React, { useEffect, useState } from 'react';
import { IProfile } from '../../core/models';
import { emptyProfile } from '../../core/constants';
import { Button, Col, Container, Modal, Row, Tab, Tabs } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { ProfilesService } from '../../services/Profiles';
import { Loading } from '../../components';
import UpdateProfilePhotoPage from '../UpdateProfilePhoto';
const ProfileDetailsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState<IProfile>(emptyProfile);
  const [openImage, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const getProfile = async () => {
    setLoading(true);
    try {
      const res = await ProfilesService.getProfileById(Number(id));
      if (res.status === 200) {
        const data: IProfile = res.data;
        setProfile(data);
        if (data.profileImages.length > 0) {
          setImage(data.profileImages[0].imageUrl);
        }
      } else {
        alert('Error while fetching profile details');
      }
      setLoading(false);
    } catch (error) {
      alert('Error while fetching profile details');
      setLoading(false);
    }
  };
  useEffect(() => {
    getProfile();
  }, []);
  return (
    <Container>
      <Row>
        <h2 className="page-title my-2">Profile Details</h2>
      </Row>
      {!loading && profile?.id && (
        <Row>
          <Col md={12}>
            <h3>File Number : {profile.fileNo}</h3>
            <h5>SGS Number : {profile.sgsNo}</h5>
          </Col>
          <Col md={12}>
            <Tabs defaultActiveKey="personal" className="mb-3">
              <Tab eventKey="personal" title="Personal">
                <Row className="my-3">
                  <Col md={7}>
                    <div className="box p-3">
                      <h4>Basic Details</h4>
                      <DetailsInfo label="Name" value={profile.name} />
                      <DetailsInfo label="Gender" value={profile.gender} />
                      <DetailsInfo
                        label="Marital Status"
                        value={profile.personalDetails.maritalStatus}
                      />
                      <DetailsInfo label="Caste" value={profile.caste} />
                      <DetailsInfo label="Subcaste" value={profile.subcaste} />
                      <DetailsInfo label="Bedagu" value={profile.bedagu} />
                    </div>
                    <div className="box my-3 p-3">
                      <h4>Birth Details</h4>
                      <DetailsInfo label="Date of Birth" value={profile.birthDetails.dob} />
                      <DetailsInfo label="Birth Place " value={profile.birthDetails.birthPlace} />
                      <DetailsInfo label="Birth Time" value={profile.birthDetails.birthTime} />
                      {profile.birthDetails.birthDay && (
                        <DetailsInfo label="Birth Day" value={profile.birthDetails.birthDay} />
                      )}
                      <DetailsInfo label="Moon Sign" value={profile.birthDetails.moonsign} />
                      <DetailsInfo label="Star" value={profile.birthDetails.star} />
                      <DetailsInfo label="Gothra" value={profile.birthDetails.gothra} />
                      <DetailsInfo label="Pada" value={profile.birthDetails.pada} />
                    </div>
                    <div className="box my-3 p-3">
                      <h4>Personal Details</h4>
                      <DetailsInfo label="Height" value={profile.personalDetails.height} />
                      <DetailsInfo label="Weight" value={profile.personalDetails.weight} />
                      <DetailsInfo label="Blood Group" value={profile.personalDetails.bloodGroup} />
                      <DetailsInfo label="Body Type" value={profile.personalDetails.bodyType} />
                      <DetailsInfo label="Complexion" value={profile.personalDetails.complexion} />
                      {profile.personalDetails.interestedInForeignVisits && (
                        <DetailsInfo
                          label="Interested in foreign visit"
                          value={profile.personalDetails.interestedInForeignVisits}
                        />
                      )}
                    </div>
                  </Col>
                  <Col md={5}>
                    <div className="profile-img-container">
                      {openImage && (
                        <img src={openImage} alt="lingayath weds" className="img-main mb-3" />
                      )}{' '}
                      <div>
                        {profile.profileImages.map((i) => (
                          <img
                            src={i.imageUrl}
                            alt="lingayath weds"
                            onClick={() => setImage(i.imageUrl)}
                            className={
                              openImage === i.imageUrl
                                ? 'img-thumb active m-2 pointer'
                                : 'img-thumb m-2 pointer'
                            }
                          />
                        ))}
                      </div>
                      <div className="my-2">
                        <Button
                          onClick={() => navigate('/dashboard/change-profile-photo/' + profile.id)}
                          // onClick={() => setModal(true)}
                        >
                          {openImage ? 'Change Photo' : 'Upload Photo'}
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="family" title="Family">
                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Family Details</h4>
                  <DetailsInfo
                    label="Father"
                    value={`${profile.familyDetails.fatherName} (${profile.familyDetails.fatherAlive})`}
                  />
                  <DetailsInfo
                    label="Father Occupation"
                    value={profile.familyDetails.fatherOccupation}
                  />
                  <DetailsInfo
                    label="Mother"
                    value={`${profile.familyDetails.motherName} (${profile.familyDetails.motherAlive})`}
                  />
                  <DetailsInfo
                    label="Mother Occupation"
                    value={profile.familyDetails.motherOccupation}
                  />
                  <DetailsInfo label="Brothers" value={profile.familyDetails.noOfBrothers} />
                  <DetailsInfo
                    label="Married Brothers"
                    value={profile.familyDetails.noOfBrothersMarried}
                  />
                  <DetailsInfo label="Sisters" value={profile.familyDetails.noOfSisters} />
                  <DetailsInfo
                    label="Married Sisters"
                    value={profile.familyDetails.noOfSistersMarried}
                  />
                </div>
                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Contact Details </h4>
                  <DetailsInfo label="Phone No." value={profile.contactDetails.mobileNo1} />
                  <DetailsInfo label="Alternate No." value={profile.contactDetails.mobileNo2} />
                  <DetailsInfo label="Email" value={profile.contactDetails.email} />
                  <DetailsInfo label="Address 1" value={profile.contactDetails.address1} />
                  <DetailsInfo label="Adddress 2" value={profile.contactDetails.address2} />
                  <DetailsInfo label="District" value={profile.contactDetails.district} />
                  <DetailsInfo label="Resident At" value={profile.contactDetails.residentAt} />
                </div>
              </Tab>
              <Tab eventKey="education" title="Education">
                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Education and Profession</h4>
                  <DetailsInfo
                    label="Edcation Stream"
                    value={profile.educationsDetails.educationStream}
                  />
                  <DetailsInfo
                    label="Qualification"
                    value={profile.educationsDetails.qualification}
                  />
                  <DetailsInfo label="Occupation" value={profile.educationsDetails.occupation} />
                  <DetailsInfo label="Working In" value={profile.educationsDetails.workingIn} />
                  <DetailsInfo
                    label="Annual Income"
                    value={profile.educationsDetails.annualIncome}
                  />
                  <DetailsInfo label="Job Type" value={profile.educationsDetails.jobType} />
                </div>
              </Tab>
              <Tab eventKey="looking" title="Looking For">
                <div className="box my-3 p-3">
                  <h4 className="profile-heading text-dark2">Looking For</h4>
                  <DetailsInfo
                    label="Age"
                    value={
                      profile.lookingForDetails.ageFrom
                        ? `${profile.lookingForDetails.ageFrom} to ${profile.lookingForDetails.ageTo}`
                        : ''
                    }
                  />
                  <DetailsInfo label="Caste" value={profile.lookingForDetails.caste} />
                  <DetailsInfo label="Subcaste" value={profile.lookingForDetails.subCaste} />
                  <DetailsInfo label="Height" value={profile.lookingForDetails.height} />
                  <DetailsInfo
                    label="Qualification"
                    value={profile.lookingForDetails.qualification}
                  />
                  <DetailsInfo label="Occupation" value={profile.lookingForDetails.occupation} />
                  <DetailsInfo
                    label="Annual Income"
                    value={profile.lookingForDetails.annualIncome}
                  />
                  <DetailsInfo
                    label="Marital Status"
                    value={profile.lookingForDetails.maritalStatus}
                  />
                </div>
              </Tab>
              <Tab eventKey="payment" title="Payment">
                {profile.paymentsDetails.map((i) => (
                  <div className="box my-3 p-3">
                    <DetailsInfo label="Mode" value={i.paymentMode} />
                    <DetailsInfo label="Amount" value={i.amount} />
                    <DetailsInfo label="Date" value={i.paymentDate} />
                    <DetailsInfo label="Transaction ID" value={i.transactionId} />
                  </div>
                ))}
              </Tab>
            </Tabs>
          </Col>
          <Col md={12}>
            <Button onClick={() => navigate('/dashboard/edit-profile/' + profile.id)}>
              Edit Profile
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12}></Col>
      </Row>
      {loading && (
        <Row className="my-5 py-5">
          <Loading />
        </Row>
      )}
      {!loading && !profile?.id && (
        <Row className="my-5 py-5">
          <h4 className="text-secondary text-center">Profile Not Found</h4>
        </Row>
      )}
      <Modal show={modal} onHide={() => setModal(false)}>
        <UpdateProfilePhotoPage />
      </Modal>
    </Container>
  );
};
interface DetailsInfoProps {
  label: string;
  value: string | number;
}
const DetailsInfo = (props: DetailsInfoProps) => {
  return (
    <div className="details-info">
      <div className="label">{props.label}</div>:<div className="ps-1 value">{props.value}</div>
    </div>
  );
};
export default ProfileDetailsPage;
