import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row, Table } from 'react-bootstrap';
import { UserService } from '../../services';
import { AppPagination, Loading } from '../../components';
import { IUser } from '../../core/models';
import UserModal from './UserModal';
import { emptyUser } from '../../core/constants';
import { ModalModes } from '../../core/enums';
const UsersPage = () => {
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState<Boolean>(false);
  const [pagination, setPagination] = useState({
    cur: 1,
    max: 0,
  });
  const [modal, setModal] = useState<{
    show: boolean;
    user: IUser;
    mode: ModalModes;
  }>({
    show: false,
    user: emptyUser,
    mode: ModalModes.VIEW,
  });
  const getUsers = async (page: number = 1) => {
    setLoading(true);
    try {
      const res = await UserService.getUsers(page);
      if (res.data.records.length > 0) {
        setUsers(res.data.records);
        setPagination({
          cur: res.data.pagination.page,
          max: res.data.pagination.pages,
        });
      } else {
        setUsers([]);
        setPagination({
          cur: 1,
          max: 0,
        });
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };
  const onAddUser = () => {
    setModal({
      show: true,
      user: emptyUser,
      mode: ModalModes.ADD,
    });
  };
  const onViewUser = (user: IUser) => {
    setModal({
      show: true,
      user: user,
      mode: ModalModes.VIEW,
    });
  };
  const onCloseModal = (e: boolean) => {
    setModal({
      show: false,
      user: emptyUser,
      mode: ModalModes.VIEW,
    });
    if (e) getUsers();
  };
  useEffect(() => {
    getUsers();
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h2 className="page-title my-2">Users</h2>
          </Col>
          <Col md={12} className="text-end">
            <Button onClick={onAddUser}>Add User</Button>
          </Col>
        </Row>
        {!loading && users.length > 0 && (
          <Row className="my-5">
            <Col>
              <Table striped hover>
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Branch</th>
                  </tr>
                </thead>
                <tbody>
                  {users.map((i, index) => (
                    <tr key={i.id} onClick={() => onViewUser(i)}>
                      <td>{index + 1}</td>
                      <td>{i.name}</td>
                      <td>{i.email}</td>
                      <td>{i.roleDetails.role}</td>
                      <td>{i.branchesDetails?.branch_name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
            <div className="d-flex justify-content-center my-3">
              <AppPagination
                curPage={pagination.cur}
                maxPage={pagination.max}
                changePage={(n) => getUsers(n)}
              />
            </div>
          </Row>
        )}
        {loading && <Loading />}
        {!loading && users.length === 0 && (
          <h4 className="text-secondary text-center my-5 py-5">No Records Found</h4>
        )}
      </Container>
      <UserModal show={modal.show} user={modal.user} mode={modal.mode} onClose={onCloseModal} />
    </>
  );
};
export default UsersPage;
