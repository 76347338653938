export const AppIcons = {
  activeProfile: require('./images/activeProfile.png'),
  allProfiles: require('./images/allProfiles.png'),
  bride: require('./images/bride.png'),
  groom: require('./images/groom.png'),
  couple: require('./images/couple.png'),
  newProfile: require('./images/newProfile.png'),
  profile: require('./images/profile.png'),
  gallery: require('./images/gallery.png'),
  testimonial: require('./images/testimonial.png'),
};
export const AppImages = {
  landing: require('./images/landing.jpg'),
};
