import React, { useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: '',
    code: '',
  });
  const [error, setError] = useState({
    email: '',
    password: '',
    code: '',
  });
  const isValid = () => {
    let temp = { email: '', password: '', code: '' };
    let flag = true;
    if (!loginForm.email) {
      temp.email = 'Required';
      flag = false;
    }
    if (!loginForm.password) {
      temp.password = 'Required';
      flag = false;
    }
    if (!loginForm.code) {
      temp.code = 'Required';
      flag = false;
    }
    setError(temp);
    return flag;
  };
  const onResetPassword = () => {
    if (isValid()) {
      //api call
    }
  };
  return (
    <div className="full-page d-flex align-items-center justify-content-center bg-light">
      <div className="login box p-3">
        <h2 className="script border-bottom text-center mb-4 pb-2">Lingayath Weds</h2>
        <p className="text-center bold fs-5">Reset Your Password</p>
        <FloatingLabel label="Email" className="my-3">
          <Form.Control
            name="email"
            value={loginForm.email}
            onChange={(e) => setLoginForm({ ...loginForm, email: e.target.value })}
            type="email"
            isInvalid={!!error.email}
            placeholder="Email"
          />
          {error.email && <p className="error">{error.email}</p>}
        </FloatingLabel>
        <FloatingLabel label="Validation Code" className="my-3">
          <Form.Control
            name="code"
            value={loginForm.code}
            onChange={(e) => setLoginForm({ ...loginForm, code: e.target.value })}
            isInvalid={!!error.code}
            placeholder="Validation Code"
          />
          {error.code && <p className="error">{error.code}</p>}
        </FloatingLabel>
        <FloatingLabel label="Password" className="my-3">
          <Form.Control
            name="password"
            placeholder="Password"
            value={loginForm.password}
            onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
            type="password"
            isInvalid={!!error.password}
          />
          {error.password && <p className="error">{error.password}</p>}
        </FloatingLabel>
        <div className="d-grid my-3">
          <Button onClick={onResetPassword}>Change Password</Button>
        </div>
        <div className="text-center my-3">
          <p className="link" onClick={() => navigate('/auth/login')}>
            Cancel
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
