import React, { useEffect, useState } from 'react';
import { IAddProfile } from './types';
import { Button, Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap';
import { MasterService, ProfilesService } from '../../services';
import { BranchDetails } from '../../core/models';
import {
  AnnualIncome,
  BloodGroup,
  BodyType,
  Castes,
  Complexions,
  Districts,
  EducationStream,
  Heights,
  MaritalStatus,
  Moonsigns,
  Padas,
  Stars,
  Weights,
  JobTypes,
} from '../../core/constants';
import { useNavigate } from 'react-router-dom';
import { LoadingModal } from '../../components';
import { Capitalize } from '../../core/utils';
import CreatableSelect from 'react-select/creatable';

const AddProfilePage = () => {
  const navigate = useNavigate();
  const [newDistrict, setNewDistrict] = useState('');
  const [profile, setProfile] = useState<IAddProfile>({
    name: '',
    gender: '',
    caste: '',
    subcaste: '',
    bedagu: '',
    fileNo: '',
    branchDetails: {
      id: -1,
    },
    profileStatusDetails: {
      id: 2,
    },
    birthDetails: {
      birthPlace: '',
      birthTime: '',
      birthDay: '',
      star: '',
      moonsign: '',
      gothra: '',
      pada: '',
      dob: '',
    },
    familyDetails: {
      fatherName: '',
      fatherAlive: '',
      fatherOccupation: '',
      motherName: '',
      motherAlive: '',
      motherOccupation: '',
      noOfBrothers: undefined,
      noOfBrothersMarried: undefined,
      noOfSisters: undefined,
      noOfSistersMarried: undefined,
    },
    personalDetails: {
      height: '',
      weight: '',
      bloodGroup: '',
      bodyType: '',
      complexion: '',
      maritalStatus: '',
      interestedInForeignVisits: '',
    },
    educationsDetails: {
      educationStream: '',
      qualification: '',
      occupation: '',
      workingIn: '',
      annualIncome: '',
      jobType: '',
    },
    contactDetails: {
      address1: '',
      address2: '',
      district: '',
      email: '',
      mobileNo1: '',
      mobileNo2: '',
      residentAt: '',
    },
    lookingForDetails: {
      ageFrom: undefined,
      ageTo: undefined,
      height: '',
      caste: '',
      subCaste: '',
      occupation: '',
      annualIncome: '',
      maritalStatus: '',
      educationStream: '',
      qualification: '',
    },
    paymentsDetails: {
      paymentDate: '',
      paymentType: 'Registration',
      paymentMode: '',
      amount: undefined,
      transactionId: '',
      comments: '',
    },
    images: [],
  });
  const [error, setError] = useState({
    name: '',
    gender: '',
    branch: '',
    image: '',
    caste: '',
    dob: '',
    birthPlace: '',
    fname: '',
    fa: '',
    fo: '',
    mname: '',
    ma: '',
    mo: '',
    height: '',
    weight: '',
    marital: '',
    stream: '',
    qualification: '',
    address: '',
    district: '',
    mobile: '',
    mobile2: '',
    fromage: '',
    fileNo: '',
  });
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState<{ id: number; district: string }[]>([]);
  const [branches, setBranches] = useState<BranchDetails[]>([]);
  const handleFileInputChange = (event: any) => {
    const allFiles = Array.from(event.target.files as FileList);
    const selectedFiles = allFiles.filter((file) => file.size < 300 * 1024); // Filter files less than 300 KB
    if (selectedFiles.length !== allFiles.length) {
      alert('Some files are larger than 300 KB and were not selected.');
      event.target.value = null;
      event.target.files = null;
      setError({ ...error, image: 'Image cannot be more than 300KB' });
      return;
    }
    if (selectedFiles.length > 2) {
      alert('You can only select up to 2 files');
      event.target.value = null;
      event.target.files = null;
      setError({ ...error, image: 'Maximum 2 images are allowed' });
      return;
    }
    const promises = selectedFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({ name: file.name, base64: e.target.result });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });
    Promise.all(promises)
      .then((base64Files: any) => {
        setProfile({
          ...profile,
          images: base64Files.map((x: { base64: string }) => x.base64.split('base64,')[1]),
        });
      })
      .catch((error) => {
        console.error('Error reading files:', error);
      });
  };
  const getBranches = async () => {
    try {
      const res = await MasterService.getBranches(1, 20);
      if (res.status === 200) {
        setBranches(res.data.records);
      }
    } catch (error) {}
  };
  const fromAge = () => {
    let ary: number[] = [];
    for (let i = 18; i < 50; i++) {
      ary.push(i);
    }
    return ary;
  };
  const toAge = () => {
    let ary: number[] = [];
    for (let i = profile.lookingForDetails.ageFrom; i < 50; i++) {
      ary.push(i);
    }
    return ary;
  };
  const validation = () => {
    let tempError = {
      name: '',
      gender: '',
      branch: '',
      image: '',
      caste: '',
      dob: '',
      birthPlace: '',
      fname: '',
      fa: '',
      fo: '',
      mname: '',
      ma: '',
      mo: '',
      height: '',
      weight: '',
      marital: '',
      stream: '',
      qualification: '',
      address: '',
      district: '',
      mobile: '',
      mobile2: '',
      fromage: '',
      fileNo: '',
    };
    let valid = true;
    if (!profile.name) {
      tempError.name = 'Name is required';
      valid = false;
    }
    if (!profile.gender) {
      tempError.gender = 'Gender is required';
      valid = false;
    }
    if (profile.branchDetails.id === -1) {
      tempError.branch = 'Branch is required';
      valid = false;
    }
    if (profile.images.length < 1) {
      tempError.image = 'Select atleast 1 image';
      valid = false;
    }
    if (!profile.caste) {
      tempError.caste = 'Caste is required';
      valid = false;
    }
    if (!profile.birthDetails.dob) {
      tempError.dob = 'DOB is required';
      valid = false;
    }
    if (!profile.birthDetails.birthPlace) {
      tempError.birthPlace = 'Birth place is required';
      valid = false;
    }
    if (!profile.familyDetails.fatherName) {
      tempError.fname = 'Father name is required';
      valid = false;
    }
    if (!profile.familyDetails.fatherAlive) {
      tempError.fa = 'Mandatory Field';
      valid = false;
    }
    if (!profile.familyDetails.fatherOccupation) {
      tempError.fo = 'Mandatory Field';
      valid = false;
    }
    if (!profile.familyDetails.motherName) {
      tempError.mname = 'Mother name is required';
      valid = false;
    }
    if (!profile.familyDetails.motherAlive) {
      tempError.ma = 'Mandatory Field';
      valid = false;
    }
    if (!profile.familyDetails.motherOccupation) {
      tempError.mo = 'Mandatory Field';
      valid = false;
    }
    if (!profile.personalDetails.height) {
      tempError.height = 'Mandatory Field';
      valid = false;
    }
    if (!profile.personalDetails.weight) {
      tempError.weight = 'Mandatory Field';
      valid = false;
    }
    if (!profile.personalDetails.maritalStatus) {
      tempError.marital = 'Mandatory Field';
      valid = false;
    }
    if (!profile.educationsDetails.educationStream) {
      tempError.stream = 'Mandatory Field';
      valid = false;
    }
    if (!profile.educationsDetails.qualification) {
      tempError.qualification = 'Mandatory Field';
      valid = false;
    }
    if (!profile.contactDetails.address1) {
      tempError.address = 'Mandatory Field';
      valid = false;
    }
    if (!profile.contactDetails.mobileNo1) {
      tempError.mobile = 'Mandatory Field';
      valid = false;
    }
    if (profile.contactDetails.mobileNo1 && !profile.contactDetails.mobileNo1.match(/^\d{10}$/)) {
      tempError.mobile = 'Enter Valid Ph.No';
      valid = false;
    }
    if (profile.contactDetails.mobileNo2 && !profile.contactDetails.mobileNo2.match(/^\d{10}$/)) {
      tempError.mobile2 = 'Enter Valid Ph.No';
      valid = false;
    }
    if (profile.lookingForDetails.ageFrom && !profile.lookingForDetails.ageTo) {
      tempError.fromage = 'Select To Age';
      valid = false;
    }
    if (!profile.fileNo) {
      tempError.fileNo = 'Mandatory Field';
      valid = false;
    }
    setError(tempError);
    return valid;
  };
  const register = () => {
    if (validation()) {
      addProfile();
    }
  };

  const addProfile = async () => {
    setLoading(true);
    try {
      const res = await ProfilesService.addProfile({
        ...profile,
        paymentsDetails:
          profile.paymentsDetails.paymentMode && profile.paymentsDetails.paymentDate
            ? profile.paymentsDetails
            : null,
      });
      if (newDistrict) addDistrict(newDistrict);
      if (res.status === 200) {
        alert('Registration Success');
        navigate('/dashboard/all-profiles');
      } else {
        alert('Error while registration');
      }
      setLoading(false);
    } catch (error) {
      alert('Error while registration');
      setLoading(false);
    }
  };
  const addDistrict = async (e: string) => {
    try {
      const res = await MasterService.addDistrict(e);
    } catch (error) {
      console.log(error);
    }
  };
  const getDistricts = async () => {
    try {
      const res = await MasterService.getDistricts(1, 1000);
      setDistricts(res.data.records);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBranches();
    getDistricts();
  }, []);

  return (
    <>
      <h1 className="heading bg-light mb-0 text-center py-4">Registration</h1>
      <Container>
        <Row className="my-4">
          <Col>
            <div className="box p-3">
              <h3>Basic Information</h3>
              <Row>
                <Col lg={4}>
                  <FloatingLabel label="File No." className="mb-3">
                    <Form.Control
                      placeholder="File No."
                      value={profile.fileNo}
                      onChange={(e) => setProfile({ ...profile, fileNo: e.target.value })}
                      isInvalid={!!error.fileNo}
                    />
                    <p className="error">{error.fileNo}</p>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <FloatingLabel label="Name*" className="mb-3">
                    <Form.Control
                      placeholder="Name*"
                      value={profile.name}
                      onChange={(e) => setProfile({ ...profile, name: Capitalize(e.target.value) })}
                      isInvalid={!!error.name}
                    />
                    <p className="error">{error.name}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={4}>
                  <span className="d-flex align-items-center">
                    <p className="fs-5 mb-0">Gender* : </p>
                    <Form.Check
                      className="fs-5 mx-2"
                      type="radio"
                      name="gender"
                      label="Male"
                      checked={profile.gender === 'MALE'}
                      onChange={() => {
                        setProfile({ ...profile, gender: 'MALE' });
                      }}
                    />
                    <Form.Check
                      className="fs-5 mx-2"
                      type="radio"
                      name="gender"
                      label="Female"
                      checked={profile.gender === 'FEMALE'}
                      onChange={() => {
                        setProfile({ ...profile, gender: 'FEMALE' });
                      }}
                    />
                  </span>
                  <p className="error">{error.gender}</p>
                </Col>
                <Col lg={4}>
                  <Form.Group controlId="formFileMultiple" className="mb-3">
                    <Form.Label>Upload Your Image*</Form.Label>
                    <Form.Control
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      multiple
                      onChange={handleFileInputChange}
                      isInvalid={!!error.image}
                    />
                    <p className="text-sm">Min 1, Max 2 | Max Size 300kb | Dimension 300*400</p>
                  </Form.Group>
                  <p className="error">{error.image}</p>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col lg={3}>
                  <FloatingLabel label="Branch*">
                    <Form.Select
                      className="mb-3"
                      value={profile.branchDetails.id}
                      onChange={(e) =>
                        setProfile({ ...profile, branchDetails: { id: Number(e.target.value) } })
                      }
                      isInvalid={!!error.branch}
                    >
                      <option>Select Branch</option>
                      {branches?.map((x) => (
                        <option key={x.id} value={x.id}>
                          {x.branch_name} - {x.phone_number}
                        </option>
                      ))}
                    </Form.Select>
                    <p className="error">{error.branch}</p>
                  </FloatingLabel>
                </Col>

                <Col lg={3}>
                  <FloatingLabel label="Caste*">
                    <Form.Select
                      className="mb-3"
                      value={profile.caste}
                      onChange={(e) => setProfile({ ...profile, caste: e.target.value })}
                      isInvalid={!!error.caste}
                    >
                      <option>Select Caste</option>
                      {Castes?.map((x) => (
                        <option key={x.id} value={x.caste}>
                          {x.caste}
                        </option>
                      ))}
                    </Form.Select>
                    <p className="error">{error.caste}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Subcaste" className="mb-3">
                    <Form.Control
                      placeholder="Subcaste"
                      value={profile.subcaste}
                      onChange={(e) => setProfile({ ...profile, subcaste: e.target.value })}
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Bedagu" className="mb-3">
                    <Form.Control
                      placeholder="Bedagu"
                      value={profile.bedagu}
                      onChange={(e) => setProfile({ ...profile, bedagu: e.target.value })}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
            <div className="box p-3 my-3">
              <h3>Birth Details</h3>
              <Row>
                <Col lg={3}>
                  <FloatingLabel label="Date of Birth*" className="mb-3">
                    <Form.Control
                      placeholder="Date of Birth*"
                      required
                      value={profile.birthDetails.dob}
                      type="date"
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          birthDetails: { ...profile.birthDetails, dob: e.target.value },
                        })
                      }
                      isInvalid={!!error.dob}
                    />
                    <p className="error">{error.dob}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Birth Place*" className="mb-3">
                    <Form.Control
                      placeholder="Birth Place*"
                      required
                      value={profile.birthDetails.birthPlace}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          birthDetails: { ...profile.birthDetails, birthPlace: e.target.value },
                        })
                      }
                      isInvalid={!!error.birthPlace}
                    />
                    <p className="error">{error.birthPlace}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Birth Time" className="mb-3">
                    <Form.Control
                      placeholder="Birth Time"
                      type="time"
                      value={profile.birthDetails.birthTime}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          birthDetails: { ...profile.birthDetails, birthTime: e.target.value },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Birth Day" className="mb-3">
                    <Form.Control
                      placeholder="Birth Day"
                      value={profile.birthDetails.birthDay}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          birthDetails: { ...profile.birthDetails, birthDay: e.target.value },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <FloatingLabel label="Moonsign">
                    <Form.Select
                      value={profile.birthDetails.moonsign}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          birthDetails: { ...profile.birthDetails, moonsign: e.target.value },
                        })
                      }
                    >
                      <option>Select Moonsign</option>
                      {Moonsigns?.map((x) => (
                        <option key={x.id} value={x.moon_sign}>
                          {x.moon_sign}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Star (Nakshatra)">
                    <Form.Select
                      value={profile.birthDetails.star}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          birthDetails: { ...profile.birthDetails, star: e.target.value },
                        })
                      }
                    >
                      <option>Select Star</option>
                      {Stars?.map((x) => (
                        <option key={x.id} value={x.star}>
                          {x.star}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Gothra">
                    <Form.Control
                      value={profile.birthDetails.gothra}
                      placeholder="Gothra"
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          birthDetails: { ...profile.birthDetails, gothra: e.target.value },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Pada">
                    <Form.Select
                      value={profile.birthDetails.pada}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          birthDetails: { ...profile.birthDetails, pada: e.target.value },
                        })
                      }
                    >
                      <option>Select Pada</option>
                      {Padas?.map((x) => (
                        <option key={x.id} value={x.pada}>
                          {x.pada}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
            <div className="box my-3 p-3">
              <h3>Family Details</h3>
              <Row>
                <Col lg={4}>
                  <FloatingLabel label="Father Name*" className="mb-3">
                    <Form.Control
                      placeholder="Father Name*"
                      required
                      value={profile.familyDetails.fatherName}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: {
                            ...profile.familyDetails,
                            fatherName: Capitalize(e.target.value),
                          },
                        })
                      }
                      isInvalid={!!error.fname}
                    />
                    <p className="error">{error.fname}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={4}>
                  <span className="d-flex align-items-center">
                    <p className="fs-5 mb-0">Father Alive* : </p>
                    <Form.Check
                      className="fs-5 mx-2"
                      type="radio"
                      name="father"
                      label="Alive"
                      checked={profile.familyDetails.fatherAlive === 'Alive'}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: { ...profile.familyDetails, fatherAlive: 'Alive' },
                        })
                      }
                      isInvalid={!!error.fa}
                    />

                    <Form.Check
                      className="fs-5 mx-2"
                      type="radio"
                      name="father"
                      label="Not Alive"
                      checked={profile.familyDetails.fatherAlive === 'Not Alive'}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: { ...profile.familyDetails, fatherAlive: 'Not Alive' },
                        })
                      }
                      isInvalid={!!error.fa}
                    />
                  </span>
                  <p className="error">{error.fa}</p>
                </Col>
                <Col lg={4}>
                  <FloatingLabel label="Father Occupation*" className="mb-3">
                    <Form.Control
                      placeholder="Father Occupation*"
                      value={profile.familyDetails.fatherOccupation}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: {
                            ...profile.familyDetails,
                            fatherOccupation: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.fo}
                    />
                    <p className="error">{error.fo}</p>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <FloatingLabel label="Mother Name*" className="mb-3">
                    <Form.Control
                      placeholder="Mother Name*"
                      required
                      value={profile.familyDetails.motherName}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: {
                            ...profile.familyDetails,
                            motherName: Capitalize(e.target.value),
                          },
                        })
                      }
                      isInvalid={!!error.mname}
                    />
                    <p className="error">{error.mname}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={4}>
                  <span className="d-flex align-items-center">
                    <p className="fs-5 mb-0">Mother Alive* : </p>
                    <Form.Check
                      className="fs-5 mx-2"
                      type="radio"
                      name="mother"
                      label="Alive"
                      checked={profile.familyDetails.motherAlive === 'Alive'}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: { ...profile.familyDetails, motherAlive: 'Alive' },
                        })
                      }
                    />
                    <Form.Check
                      className="fs-5 mx-2"
                      type="radio"
                      name="mother"
                      label="Not Alive"
                      checked={profile.familyDetails.motherAlive === 'Not Alive'}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: { ...profile.familyDetails, motherAlive: 'Not Alive' },
                        })
                      }
                    />
                  </span>
                  <p className="error">{error.ma}</p>
                </Col>
                <Col lg={4}>
                  <FloatingLabel label="Mother Occupation*" className="mb-3">
                    <Form.Control
                      placeholder="Mother Occupation*"
                      value={profile.familyDetails.motherOccupation}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: {
                            ...profile.familyDetails,
                            motherOccupation: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.mo}
                    />
                    <p className="error">{error.mo}</p>
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <FloatingLabel label="No. of Brothers">
                    <Form.Select
                      value={profile.familyDetails.noOfBrothers}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: {
                            ...profile.familyDetails,
                            noOfBrothers: Number(e.target.value),
                          },
                        })
                      }
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Married Brothers">
                    <Form.Select
                      value={profile.familyDetails.noOfBrothersMarried}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: {
                            ...profile.familyDetails,
                            noOfBrothersMarried: Number(e.target.value),
                          },
                        })
                      }
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="No. of Sisters">
                    <Form.Select
                      value={profile.familyDetails.noOfSisters}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: {
                            ...profile.familyDetails,
                            noOfSisters: Number(e.target.value),
                          },
                        })
                      }
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Married Sisters">
                    <Form.Select
                      value={profile.familyDetails.noOfSistersMarried}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          familyDetails: {
                            ...profile.familyDetails,
                            noOfSistersMarried: Number(e.target.value),
                          },
                        })
                      }
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
            <div className="box my-2 p-3">
              <h3>Personal Details</h3>
              <Row>
                <Col lg={3}>
                  <FloatingLabel label="Height*">
                    <Form.Select
                      className="mb-3"
                      value={profile.personalDetails.height}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          personalDetails: {
                            ...profile.personalDetails,
                            height: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.height}
                    >
                      <option>Select Height</option>
                      {Heights.map((x) => (
                        <option key={x.height} value={x.height}>
                          {x.height}
                        </option>
                      ))}
                    </Form.Select>
                    <p className="error">{error.height}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Weight*">
                    <Form.Select
                      className="mb-3"
                      value={profile.personalDetails.weight}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          personalDetails: {
                            ...profile.personalDetails,
                            weight: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.weight}
                    >
                      <option>Select Weight</option>
                      {Weights.map((x) => (
                        <option key={x.weight} value={x.weight}>
                          {x.weight}
                        </option>
                      ))}
                    </Form.Select>
                    <p className="error">{error.weight}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Blood Group">
                    <Form.Select
                      className="mb-3"
                      value={profile.personalDetails.bloodGroup}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          personalDetails: {
                            ...profile.personalDetails,
                            bloodGroup: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Select Blood Group</option>
                      {BloodGroup.map((x) => (
                        <option key={x.blood_group} value={x.blood_group}>
                          {x.blood_group}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Body Type">
                    <Form.Select
                      className="mb-3"
                      value={profile.personalDetails.bodyType}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          personalDetails: {
                            ...profile.personalDetails,
                            bodyType: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Select Body Type</option>
                      {BodyType.map((x) => (
                        <option key={x.body_type} value={x.body_type}>
                          {x.body_type}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Complexion">
                    <Form.Select
                      className="mb-3"
                      value={profile.personalDetails.complexion}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          personalDetails: {
                            ...profile.personalDetails,
                            complexion: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Select Complexion</option>
                      {Complexions.map((x) => (
                        <option key={x.complexion} value={x.complexion}>
                          {x.complexion}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Marital Status*">
                    <Form.Select
                      className="mb-3"
                      value={profile.personalDetails.maritalStatus}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          personalDetails: {
                            ...profile.personalDetails,
                            maritalStatus: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.marital}
                    >
                      <option>Select Marital Status</option>
                      {MaritalStatus.map((x) => (
                        <option key={x.marital_status} value={x.marital_status}>
                          {x.marital_status}
                        </option>
                      ))}
                    </Form.Select>
                    <p className="error">{error.marital}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={5}>
                  <span className="d-flex align-items-center">
                    <p className="fs-5 mb-0">Intrested in Foreign visits? </p>
                    <Form.Check
                      className="fs-5 mx-2"
                      type="radio"
                      name="foreign"
                      label="Yes"
                      checked={profile.personalDetails.interestedInForeignVisits === 'YES'}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          personalDetails: {
                            ...profile.personalDetails,
                            interestedInForeignVisits: 'YES',
                          },
                        })
                      }
                    />
                    <Form.Check
                      className="fs-5 mx-2"
                      type="radio"
                      name="foreign"
                      label="No"
                      checked={profile.personalDetails.interestedInForeignVisits === 'NO'}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          personalDetails: {
                            ...profile.personalDetails,
                            interestedInForeignVisits: 'NO',
                          },
                        })
                      }
                    />
                  </span>
                </Col>
              </Row>
            </div>
            <div className="box my-3 p-3">
              <h3>Educational Details</h3>
              <Row>
                <Col lg={3}>
                  <FloatingLabel label="Education Stream*">
                    <Form.Select
                      className="mb-3"
                      value={profile.educationsDetails.educationStream}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          educationsDetails: {
                            ...profile.educationsDetails,
                            educationStream: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.stream}
                    >
                      <option>Select Education Stream</option>

                      {EducationStream.map((x) => (
                        <option key={x.education_stream} value={x.education_stream}>
                          {x.education_stream}
                        </option>
                      ))}
                    </Form.Select>
                    <p className="error">{error.stream}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Education Qualification*" className="mb-3">
                    <Form.Control
                      placeholder="Education Qualification*"
                      value={profile.educationsDetails.qualification}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          educationsDetails: {
                            ...profile.educationsDetails,
                            qualification: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.qualification}
                    />
                    <p className="error">{error.qualification}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Occupation" className="mb-3">
                    <Form.Control
                      placeholder="Occupation"
                      value={profile.educationsDetails.occupation}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          educationsDetails: {
                            ...profile.educationsDetails,
                            occupation: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Working In" className="mb-3">
                    <Form.Control
                      placeholder="Working In"
                      value={profile.educationsDetails.workingIn}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          educationsDetails: {
                            ...profile.educationsDetails,
                            workingIn: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Job Type">
                    <Form.Select
                      className="mb-3"
                      value={profile.educationsDetails.jobType}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          educationsDetails: {
                            ...profile.educationsDetails,
                            jobType: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Select Job Type</option>
                      {JobTypes.map((x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Annual Income">
                    <Form.Select
                      className="mb-3"
                      value={profile.educationsDetails.annualIncome}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          educationsDetails: {
                            ...profile.educationsDetails,
                            annualIncome: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Select Annual Income</option>

                      {AnnualIncome.map((x) => (
                        <option key={x.annual_income} value={x.annual_income}>
                          {x.annual_income}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
            <div className="box my-3 p-3">
              <h3>Contact Details</h3>
              <Row>
                <Col lg={5}>
                  <FloatingLabel label="Complete Address*" className="mb-3">
                    <Form.Control
                      style={{ height: '100px' }}
                      as="textarea"
                      rows={6}
                      placeholder="Complete Address*"
                      value={profile.contactDetails.address1}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          contactDetails: {
                            ...profile.contactDetails,
                            address1: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.address}
                    />
                    <p className="error">{error.address}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={5}>
                  <FloatingLabel label="Optional Address" className="mb-3">
                    <Form.Control
                      style={{ height: '100px' }}
                      as="textarea"
                      rows={6}
                      placeholder="Optional Address"
                      value={profile.contactDetails.address2}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          contactDetails: {
                            ...profile.contactDetails,
                            address2: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  {/* <FloatingLabel label="District*">
                    <Form.Select
                      className="mb-3"
                      value={profile.contactDetails.district}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          contactDetails: {
                            ...profile.contactDetails,
                            district: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.district}
                    >
                      <option>Select District</option>

                      {Districts.map((x) => (
                        <option key={x.district} value={x.district}>
                          {x.district}
                        </option>
                      ))}
                    </Form.Select> */}
                  <CreatableSelect
                    placeholder="Select District*"
                    classNamePrefix="react-select"
                    isClearable
                    options={districts?.map((x) => ({ value: x.district, label: x.district }))}
                    value={{
                      value: profile.contactDetails.district,
                      label: profile.contactDetails.district
                        ? profile.contactDetails.district
                        : 'Select District',
                    }}
                    onCreateOption={(e) => {
                      setNewDistrict(Capitalize(e));
                      setProfile({
                        ...profile,
                        contactDetails: {
                          ...profile.contactDetails,
                          district: Capitalize(e),
                        },
                      });
                    }}
                    onChange={(e) => {
                      setProfile({
                        ...profile,
                        contactDetails: {
                          ...profile.contactDetails,
                          district: Capitalize(e?.value),
                        },
                      });
                      setNewDistrict('');
                    }}
                  />
                  <p className="error">{error.district}</p>
                  {/* </FloatingLabel> */}
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Email" className="mb-3">
                    <Form.Control
                      placeholder="Email"
                      value={profile.contactDetails.email}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          contactDetails: {
                            ...profile.contactDetails,
                            email: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Contact Number*" className="mb-3">
                    <Form.Control
                      placeholder="Contact Number*"
                      value={profile.contactDetails.mobileNo1}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          contactDetails: {
                            ...profile.contactDetails,
                            mobileNo1: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.mobile}
                    />
                    <p className="error">{error.mobile}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Alternate Number" className="mb-3">
                    <Form.Control
                      placeholder="Alternate Number"
                      value={profile.contactDetails.mobileNo2}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          contactDetails: {
                            ...profile.contactDetails,
                            mobileNo2: e.target.value,
                          },
                        })
                      }
                      isInvalid={!!error.mobile2}
                    />
                    <p className="error">{error.mobile2}</p>
                  </FloatingLabel>
                </Col>
                <Col lg={5}>
                  <FloatingLabel label="Resident At" className="mb-3">
                    <Form.Control
                      placeholder="Resident At"
                      as="textarea"
                      style={{ height: '100px' }}
                      value={profile.contactDetails.residentAt}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          contactDetails: {
                            ...profile.contactDetails,
                            residentAt: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
            <div className="box my-3 p-3">
              <h3>Looking For Details</h3>
              <Row>
                <Col lg={3} className="d-flex">
                  <FloatingLabel label="From Age" className="col-6 pe-1">
                    <Form.Select
                      className="mb-3"
                      value={profile.lookingForDetails.ageFrom}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            ageFrom: Number(e.target.value),
                          },
                        })
                      }
                    >
                      <option>From</option>

                      {fromAge().map((x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      ))}
                    </Form.Select>
                    <p className="error">{error.fromage}</p>
                  </FloatingLabel>
                  <FloatingLabel label="To Age" className="col-6">
                    <Form.Select
                      className="mb-3"
                      value={profile.lookingForDetails.ageTo}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            ageTo: Number(e.target.value),
                          },
                        })
                      }
                      disabled={profile.lookingForDetails.ageFrom ? false : true}
                    >
                      <option>To</option>

                      {toAge().map((x) => (
                        <option key={x} value={x}>
                          {x}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Partner Height">
                    <Form.Select
                      className="mb-3"
                      value={profile.lookingForDetails.height}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            height: e.target.value,
                          },
                        })
                      }
                    >
                      <option>From</option>
                      {Heights.map((x) => (
                        <option key={x.height} value={x.height}>
                          {x.height}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Partner Caste">
                    <Form.Select
                      className="mb-3"
                      value={profile.lookingForDetails.caste}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            caste: e.target.value,
                          },
                        })
                      }
                    >
                      <option>From</option>
                      {Castes.map((x) => (
                        <option key={x.caste} value={x.caste}>
                          {x.caste}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Subcaste">
                    <Form.Control
                      className="mb-3"
                      placeholder="Subcaste"
                      value={profile.lookingForDetails.subCaste}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            subCaste: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Occupation">
                    <Form.Control
                      className="mb-3"
                      placeholder="Occupation"
                      value={profile.lookingForDetails.occupation}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            occupation: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Partner Annual Income">
                    <Form.Select
                      className="mb-3"
                      value={profile.lookingForDetails.annualIncome}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            annualIncome: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Select Income</option>
                      {AnnualIncome.map((x) => (
                        <option key={x.annual_income} value={x.annual_income}>
                          {x.annual_income}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Partner Marital Status">
                    <Form.Select
                      className="mb-3"
                      value={profile.lookingForDetails.maritalStatus}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            maritalStatus: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Select Marital Status</option>
                      {MaritalStatus.map((x) => (
                        <option key={x.marital_status} value={x.marital_status}>
                          {x.marital_status}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Partner Education Stream">
                    <Form.Select
                      className="mb-3"
                      value={profile.lookingForDetails.educationStream}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            educationStream: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Select Education Stream</option>
                      {EducationStream.map((x) => (
                        <option key={x.education_stream} value={x.education_stream}>
                          {x.education_stream}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Partner Education Qualification">
                    <Form.Control
                      className="mb-3"
                      placeholder="Partner Education Qualification"
                      value={profile.lookingForDetails.qualification}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          lookingForDetails: {
                            ...profile.lookingForDetails,
                            qualification: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
            <div className="box p-3 my-3">
              <h3>Payment Details</h3>
              <Row>
                <Col lg={3}>
                  <FloatingLabel label="Payment Mode*">
                    <Form.Select
                      value={profile.paymentsDetails.paymentMode}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          paymentsDetails: {
                            ...profile.paymentsDetails,
                            paymentMode: e.target.value,
                          },
                        })
                      }
                    >
                      <option>Select Payment Mode</option>

                      <option value="Cash">Cash</option>
                      <option value="UPI">UPI</option>
                    </Form.Select>
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Amount*" className="mb-3">
                    <Form.Control
                      placeholder="Amount*"
                      required
                      value={profile.paymentsDetails.amount}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          paymentsDetails: {
                            ...profile.paymentsDetails,
                            amount: Number(e.target.value),
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Transaction ID" className="mb-3">
                    <Form.Control
                      placeholder="Transaction ID"
                      required
                      value={profile.paymentsDetails.transactionId}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          paymentsDetails: {
                            ...profile.paymentsDetails,
                            transactionId: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
                <Col lg={3}>
                  <FloatingLabel label="Payment Date" className="mb-3">
                    <Form.Control
                      placeholder="Payment Date"
                      required
                      type="date"
                      value={profile.paymentsDetails.paymentDate}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          paymentsDetails: {
                            ...profile.paymentsDetails,
                            paymentDate: e.target.value,
                          },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FloatingLabel label="Comments" className="mb-3">
                    <Form.Control
                      placeholder="Comments"
                      value={profile.paymentsDetails.comments}
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          paymentsDetails: { ...profile.paymentsDetails, comments: e.target.value },
                        })
                      }
                    />
                  </FloatingLabel>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={3} className="d-grid">
            <Button onClick={register}>Register</Button>
          </Col>
        </Row>
        <div className="my-5"></div>
      </Container>
      <LoadingModal show={loading} />
    </>
  );
};
export default AddProfilePage;
