import { HTTP } from '../core/enums';
import { ApiEndPoints, NetworkManager } from '../network';

export class MasterService {
  static getBranches = (page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.branches,
      params: { page, limit },
    });
  };
  static getDistricts = (page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.districts,
      params: { page, limit },
    });
  };
  static addDistrict = (district: string) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.districts,
      data: { district },
    });
  };
  static getRoles = (page: number = 1, limit: number = 10) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.roles,
      params: { page, limit },
    });
  };
  static dashboardData = () => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.dashbaord,
    });
  };
  static getGallery = (page: number = 1, limit: number = 20) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.GET,
      url: ApiEndPoints.gallery + '/getActiveGallery',
      params: { page, limit },
    });
  };
  static addGallery = (data: any) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.POST,
      url: ApiEndPoints.gallery + '/add',
      data: { ...data },
    });
  };
  static deleteGallery = (id: number) => {
    return NetworkManager.getInstance().appRequest({
      method: HTTP.DELETE,
      url: ApiEndPoints.gallery + '/delete/' + id,
    });
  };
}
