import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import {
  AddProfilePage,
  DashboardPage,
  DisabledProfilePage,
  ForgotPasswordPage,
  GalleryPage,
  LoginPage,
  NewProfilesPage,
  RejectedProfilesPage,
  TestimonialsPage,
  UpdateProfilePage,
  UpdateProfilePhotoPage,
  UsersPage,
} from './pages';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './css/styles.css';
import { Footer, Header } from './components';
import { UserService } from './services';
import AllProfilesPage from './pages/AllProfiles';
import ProfileDetailsPage from './pages/ProfileDetails';
const DashboardLayout = () => {
  if (UserService.isLoggedin()) {
    return (
      <>
        <div className="layout-main">
          <Header />
          <Outlet />
        </div>
        <div className="layout-footer">
          <Footer />
        </div>
      </>
    );
  } else {
    return <Navigate to={'/auth/login'} />;
  }
};
function App() {
  return (
    <div>
      <BrowserRouter basename="">
        <Routes>
          <Route path="" element={<Navigate to={'/dashboard'} />} />
          <Route path="/auth">
            <Route path="/auth/login" element={<LoginPage />} />
            <Route path="/auth/forgot-password" element={<ForgotPasswordPage />} />
          </Route>
          <Route path="/dashboard" element={<DashboardLayout />}>
            <Route path="" element={<DashboardPage />} />
            <Route path="/dashboard/users" element={<UsersPage />} />
            <Route path="/dashboard/all-profiles" element={<AllProfilesPage />} />
            <Route path="/dashboard/new-profiles" element={<NewProfilesPage />} />
            <Route path="/dashboard/rejected-profiles" element={<RejectedProfilesPage />} />
            <Route path="/dashboard/profile/:id" element={<ProfileDetailsPage />} />
            <Route path="/dashboard/add-profile" element={<AddProfilePage />} />
            <Route path="/dashboard/edit-profile/:id" element={<UpdateProfilePage />} />
            <Route
              path="/dashboard/change-profile-photo/:id"
              element={<UpdateProfilePhotoPage />}
            />
            <Route path="/dashboard/disabled-profiles" element={<DisabledProfilePage />} />
            <Route path="/dashboard/testimonials" element={<TestimonialsPage />} />
            <Route path="/dashboard/gallery" element={<GalleryPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
