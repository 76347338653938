import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { MasterService } from '../../services';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { AppPagination, Loading } from '../../components';

const GalleryPage = () => {
  const [images, setImages] = useState<{ id: number; imageUrl: string }[]>([]);
  const [newImageModal, setNewImage] = useState({
    show: false,
    image: '',
    error: '',
  });
  const [loading, setLoading] = useState<Boolean>(false);
  const [pagination, setPagination] = useState({
    cur: 1,
    max: 1,
  });
  const getImages = async (page: number = 1) => {
    setLoading(true);
    try {
      const res = await MasterService.getGallery(page);
      if (res.data?.length > 0) {
        setImages(res.data);
        setPagination({
          cur: res.pagination_data.page,
          max: res.pagination_data.pages,
        });
      } else {
        setImages([]);
        setPagination({
          cur: 1,
          max: 1,
        });
      }
      setLoading(false);
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };
  const handleFileInputChange = (event: any) => {
    const allFiles = Array.from(event.target.files as FileList);
    const selectedFiles = allFiles.filter((file) => file.size < 300 * 1024); // Filter files less than 300 KB
    if (selectedFiles.length !== allFiles.length) {
      alert('Some files are larger than 300 KB and were not selected.');
      event.target.value = null;
      event.target.files = null;
      setNewImage({
        ...newImageModal,
        error: 'Image cannot be more than 300KB',
      });
      return;
    }
    if (selectedFiles.length > 1) {
      alert('You can only select 1 file');
      event.target.value = null;
      event.target.files = null;
      setNewImage({ ...newImageModal, error: 'Maximum 1 image is allowed' });
      return;
    }
    const promises = selectedFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          resolve({ name: file.name, base64: e.target.result });
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    });
    Promise.all(promises)
      .then((base64Files: any) => {
        setNewImage({
          ...newImageModal,
          image: base64Files.map((x: { base64: string }) => x.base64.split('base64,')[1])[0],
        });
      })
      .catch((error) => {
        console.error('Error reading files:', error);
      });
  };
  const addImage = async () => {
    if (newImageModal.image) {
      try {
        const res = await MasterService.addGallery({
          imageUrl: newImageModal.image,
        });
        if (res.status === 200) {
          alert('Image added successfully');
          getImages();
          setNewImage({ show: false, image: '', error: '' });
        } else {
          alert('Error while adding image');
        }
      } catch (error) {
        alert(error.message);
      }
    } else {
      setNewImage({ ...newImageModal, error: 'Mandatory Field' });
    }
  };
  const deleteImage = async (id: number) => {
    try {
      const val = window.confirm('Do you want to delete image ?');
      if (val) {
        const res = await MasterService.deleteGallery(id);
        if (res.status === 200) {
          alert('Image deleted successfully');
          getImages();
        } else {
          alert('Error while deleting image');
        }
      }
    } catch (error) {
      alert(error.message);
    }
  };
  useEffect(() => {
    getImages();
  }, []);
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h2 className="page-title my-2">Gallery</h2>
            <div className="text-end my-2">
              <Button onClick={() => setNewImage({ show: true, image: '', error: '' })}>
                Add Image
              </Button>
            </div>
          </Col>
        </Row>
        {!loading && (
          <LightGallery speed={500} elementClassNames="row">
            {images.map((i) => (
              <a className="gallery-item col-lg-3 mb-2" data-src={i.imageUrl} key={i.id}>
                <img className="img-responsive w-100" src={i.imageUrl} />
                <Button
                  variant="outline-primary"
                  className="border-0 btn-img-update"
                  onClick={(e) => {
                    deleteImage(i.id);
                    e.stopPropagation();
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </a>
            ))}
          </LightGallery>
        )}
        {loading && <Loading />}
        {!loading && images.length === 0 && (
          <h4 className="text-secondary text-center my-5 py-5">No Records Found</h4>
        )}
        {!loading && images.length > 0 && (
          <div className="d-flex justify-content-center my-3">
            <AppPagination
              curPage={pagination.cur}
              maxPage={pagination.max}
              changePage={(n) => getImages(n)}
            />
          </div>
        )}
      </Container>
      <Modal
        show={newImageModal.show}
        onHide={() => {
          setNewImage({ show: false, image: '', error: '' });
        }}
      >
        <Modal.Header className="bg-primary text-light" closeButton>
          <Modal.Title>Add Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFileMultiple" className="mb-3">
            <Form.Label>Upload Your Image*</Form.Label>
            <Form.Control
              type="file"
              accept=".jpg,.jpeg,.png"
              multiple
              onChange={handleFileInputChange}
              isInvalid={!!newImageModal.error}
            />
            <p className="text-sm">1 image | Max Size 300kb | Dimension 300*400</p>
            {newImageModal.error && <p className="error">{newImageModal.error}</p>}
          </Form.Group>
          <div className="d-grid my-3">
            <Button
              onClick={() => {
                addImage();
              }}
            >
              Update
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default GalleryPage;
