export const ApiEndPoints = {
  login: 'api/login',
  users: 'api/users',
  branches: 'api/branches',
  districts: 'api/districts',
  roles: 'api/roles',
  profiles: 'api/profiles',
  dashbaord: 'api/profiles/admin/dashboardData',
  approve: 'api/profiles/admin/updateProfileStatus',
  testimonials: '/api/testimonials/admin',
  gallery: '/api/gallery/admin',
};
